import React, { useEffect, useRef, useState } from "react"
import { axiosInstance } from "../../config/https"
import styles from "./index.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "../../functions/toast"
import { Loader } from "../Loader"
import { useLocation, useNavigate } from "react-router-dom"
import { SvgSprite } from "../SvgSprite"
import cn from "classnames"
import { ProfileImage } from "../ProfileImage/ProfileImage"
import { Btn } from "../Btn"
import favicon from "../../../assets/images/favicon.ico"
import { Input } from "../Input"
import { Popup } from "../Popup"

function Gpt3Form({
	activeChat,
	ListMessages,
	getChats,
	setActiveChat,
	question,
	secondQuestion,
	setQuestion,
	setSecondQuestion,
	selectedAssistant,
}) {
	const currentUser = useSelector(state => state.user.userInfo)
	const [Loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const location = useLocation()
	const [models] = useState(["gpt", "claude"])
	const [messages, setMessages] = useState([])
	const [inputValue, setInputValue] = useState("")
	const users = useSelector(state => state.organization.org.users)
	const scrollableRef = useRef(null)
	const [createResource, setCreateResource] = useState(false)
	const navigate = useNavigate()
	const [selectedSuggestion, setSelectedSuggestion] = useState(null)
	const [showPolicyForm, setShowPolicyForm] = useState(false)
	const [policyFormData, setPolicyFormData] = useState({
		companyName: '',
		industry: '',
		about: '',
		framework: ''
	})
	const [formErrors, setFormErrors] = useState({})
	const [showTooltip, setShowTooltip] = useState(null)
	const [currentFramework, setCurrentFramework] = useState(null)

	const promptSuggestions = {
		governance: [
			{ text: "Generate policies", action: "openPolicyForm" },
			"How to implement effective governance policies?",
			"Best practices for corporate governance structure"
		],
		risk: [
			"How to conduct a risk assessment?",
			"What are common cybersecurity risks?",
			"Risk mitigation strategies for data protection"
		],
		compliance: [
			"Latest compliance requirements for our sector",
			"How to ensure GDPR compliance?",
			"Compliance monitoring best practices"
		],
		audit: [
			"How to prepare for an IT audit?",
			"Internal audit checklist for security",
			"Audit documentation requirements"
		]
	}

	const fieldDescriptions = {
		companyName: "Enter your organization's legal name",
		industry: "e.g., Healthcare, Technology, Finance, Manufacturing",
		about: "Provide a brief description of your organization's main activities, size, and compliance needs (100-200 words)",
		framework: "e.g., HIPAA, ISO 27001, SOC 2, GDPR, PCI DSS"
	}

	const validateForm = () => {
		const errors = {}
		if (!policyFormData.companyName.trim()) errors.companyName = "Company name is required"
		if (!policyFormData.industry.trim()) errors.industry = "Industry is required"
		if (!policyFormData.about.trim()) errors.about = "About section is required"
		if (!policyFormData.framework.trim()) errors.framework = "Framework is required"
		return errors
	}

	const updateChatHistory = (question, response) => {
		setMessages(prevChatHistory => {
			const updatedChatHistory = prevChatHistory.map(chatMessage => {
				if (chatMessage.question === question) {
					return {
						question: chatMessage.question,
						response: response,
						sender: chatMessage.sender
					}
				} else {
					return chatMessage
				}
			})
			return updatedChatHistory
		})
	}

	const handleSubmit = async () => {
		try {
			setLoading(true)

			if (!inputValue.length > 0) {
				setLoading(false)
				return
			}

			setMessages([...messages, { question: inputValue, response: "", sender: currentUser.id }])

			let path = "ask/claude"
			const searchParams = new URLSearchParams(location.search)
			const testModel = searchParams.get("test_model")

			if (models.includes(testModel?.toLowerCase())) {
				path = `ask/${testModel?.toLowerCase()}`
			}

			const data = {
				prompt: inputValue,
				title: inputValue,
				chat_id: activeChat?.id || null,
				assistant_type: selectedAssistant
			}
			setInputValue("")
			await axiosInstance
				.post(path, data)
				.then(res => {
					updateChatHistory(inputValue, res.data.answer)
					setActiveChat(res.data.chat)
					if (secondQuestion?.length > 0) {
						setQuestion(secondQuestion)
						setSecondQuestion("")
					} else {
						setQuestion("")
					}
					if (!activeChat?.id) {
						getChats()
					}
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (error) {
			dispatch(toast("error", error))
			setLoading(false)
		}
	}

	useEffect(() => {
		setMessages([...ListMessages])
	}, [ListMessages])

	useEffect(() => {
		if (question) {
			setInputValue(question)
		}
	}, [question])

	useEffect(() => {
		const scrollable = scrollableRef?.current
		scrollable.scrollTop = scrollable?.scrollHeight
	}, [messages])

	const autoResize = element => {
		element.style.height = "auto"
		element.style.height = element.scrollHeight + "px"
	}

	useEffect(() => {
		if (question) {
			setLoading(true)
			handleSubmit()
			window.history.replaceState({}, document.title)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question, inputValue])

	const navigateToResource = (content) => {
		if (!content) return;
		
		// Check if this is a policy draft content
		const policyDraftMatch = content.match(/<policy_draft(?:\s+style="[^"]*")?>([\s\S]*?)<\/policy_draft>/);
		if (policyDraftMatch) {
			// For policy drafts, use the content inside the tags
			const cleanContent = policyDraftMatch[1]
				.trim()
				.replace(/<li>\s*<\/li>/g, '');
			navigate("/resources", { state: cleanContent });
		} else {
			// For regular messages, clean up the content
			const cleanContent = content
				.replace(/<li>\s*<\/li>/g, '')
				.replace(/\n+/g, "\n")
				.trim();
			navigate("/resources", { state: cleanContent });
		}
	}

	// Reset selected suggestion when messages change
	useEffect(() => {
		setSelectedSuggestion(null);
	}, [messages.length]);

	const handleSuggestionClick = async (suggestion, index) => {
		if (typeof suggestion === 'object' && suggestion.action === 'openPolicyForm') {
			setShowPolicyForm(true);
			return;
		}
		
		const suggestionText = typeof suggestion === 'object' ? suggestion.text : suggestion;
		// Clear input immediately
		setInputValue("");
		setSelectedSuggestion(index);
		
		// Immediately submit the suggestion
		try {
			setLoading(true);
			
			setMessages([...messages, { question: suggestionText, response: "", sender: currentUser.id }]);

			let path = "ask/claude";
			const searchParams = new URLSearchParams(location.search);
			const testModel = searchParams.get("test_model");

			if (models.includes(testModel?.toLowerCase())) {
				path = `ask/${testModel?.toLowerCase()}`;
			}

			const data = {
				prompt: suggestionText,
				title: suggestionText,
				chat_id: activeChat?.id || null,
				assistant_type: selectedAssistant
			};

			await axiosInstance.post(path, data)
				.then(res => {
					updateChatHistory(suggestionText, res.data.answer);
					setActiveChat(res.data.chat);
					if (!activeChat?.id) {
						getChats();
					}
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message));
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			dispatch(toast("error", error));
			setLoading(false);
		}
	};

	const handlePolicyFormSubmit = async () => {
		const errors = validateForm()
		if (Object.keys(errors).length > 0) {
			setFormErrors(errors)
			return
		}
		try {
			setLoading(true)
			setShowPolicyForm(false)
			
			setCurrentFramework(policyFormData.framework)
			
			const data = {
				action: 'generate_policies',
				company_name: policyFormData.companyName,
				industry: policyFormData.industry,
				about: policyFormData.about,
				framework: policyFormData.framework,
				title: "Generate Policies",
				chat_id: activeChat?.id || null,
				assistant_type: selectedAssistant,
				prompt: `Generating policies for ${policyFormData.companyName} (${policyFormData.industry}) based on ${policyFormData.framework} framework`
			}

			// Add a temporary message to show the user's input
			setMessages([...messages, { 
				question: data.prompt, 
				response: "", 
				sender: currentUser.id 
			}])

			await axiosInstance.post("ask/claude", data)
				.then(res => {
					updateChatHistory(data.prompt, res.data.answer)
					setActiveChat(res.data.chat)
					if (!activeChat?.id) {
						getChats()
					}
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoading(false)
					setPolicyFormData({
						companyName: '',
						industry: '',
						about: '',
						framework: ''
					})
				})
		} catch (error) {
				dispatch(toast("error", error))
				setLoading(false)
		}
	}

	const handlePolicyClick = async (policyName) => {
		try {
			setLoading(true)
			
			const data = {
				action: 'generate_policy_draft',
				policy_type: policyName,
				title: `Draft: ${policyName}`,
				chat_id: activeChat?.id || null,
				assistant_type: selectedAssistant,
				prompt: `Drafting ${policyName}`
			}

			// Add a temporary message to show the user's input
			setMessages([...messages, { 
				question: data.prompt, 
				response: "", 
				sender: currentUser.id 
			}])

			await axiosInstance.post("ask/claude", data)
				.then(res => {
					updateChatHistory(data.prompt, res.data.answer)
					setActiveChat(res.data.chat)
					if (!activeChat?.id) {
						getChats()
					}
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (error) {
			dispatch(toast("error", error))
			setLoading(false)
		}
	}

	const handlePolicyReview = async (policyContent, policyName) => {
		try {
			setLoading(true)
			
			const data = {
				action: 'review_policy',
				policy_type: policyName,
				policy: policyContent,
				framework: currentFramework || '',
				title: `Review: ${policyName}`,
				chat_id: activeChat?.id || null,
				assistant_type: selectedAssistant,
				prompt: `Reviewing ${policyName}`
			}

			// Add a temporary message to show the user's input
			setMessages([...messages, { 
				question: data.prompt, 
				response: "", 
				sender: currentUser.id 
			}])

			await axiosInstance.post("ask/claude", data)
				.then(res => {
					updateChatHistory(data.prompt, res.data.answer)
					setActiveChat(res.data.chat)
					if (!activeChat?.id) {
						getChats()
					}
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (error) {
			dispatch(toast("error", error))
			setLoading(false)
		}
	}

	const renderMessage = (message) => {
		if (!message.response) {
			return <div className={styles.text}>{message.question}</div>
		}

		// Check if the response contains a policies list
		const policiesMatch = message.response.match(/<policies>([\s\S]*?)<\/policies>/);
		if (policiesMatch) {
			const policiesList = policiesMatch[1].trim().split('\n');
			return (
				<div className={styles.textName}>
					<div className={styles.userName}>SecureLabs AI</div>
					<div className={styles.text}>
						{message.response.split('<policies>')[0]}
						<div className={styles.policiesList}>
							{policiesList.map((policy, index) => {
								// Extract policy name (remove number and dots)
								const policyName = policy.replace(/^\d+\.\s*/, '').trim();
								return (
									<div 
										key={index} 
										className={styles.policyItem}
										onClick={() => handlePolicyClick(policyName)}
									>
										{policy}
									</div>
								);
							})}
						</div>
						{message.response.split('</policies>')[1]}
					</div>
				</div>
			);
		}

		// Check if the response contains a policy draft
		const policyDraftMatch = message.response.match(/<policy_draft(?:\s+style="[^"]*")?>([\s\S]*?)<\/policy_draft>/);
		if (policyDraftMatch) {
			const policyContent = policyDraftMatch[1].trim();
			return (
				<div className={styles.textName}>
					<div className={styles.userName}>SecureLabs AI</div>
					<div className={styles.policyDocument}>
						<div className={styles.policyDocumentHeader}>
							<div className={styles.docIcon}>
								<SvgSprite spriteID="file" className={styles.docIconSvg} />
							</div>
							<div className={styles.docTitle}>
								{message.question.replace('Drafting ', '')}
							</div>
							<div className={styles.headerButtons}>
								<Btn 
									className={styles.toResourceBtn}
									onClick={() => navigateToResource(policyContent)}
								>
									+ Resource
								</Btn>
								<Btn 
									className={styles.toResourceBtn}
									onClick={() => handlePolicyReview(policyContent, message.question.replace('Drafting ', ''))}
								>
									Review
								</Btn>
							</div>
						</div>
						<div className={styles.policyDocumentContent}>
							<div 
								className={styles.policyText}
								dangerouslySetInnerHTML={{ __html: policyContent }}
							/>
						</div>
					</div>
				</div>
			);
		}

		// Regular message rendering
		return (
			<div className={styles.textName}>
				<div className={styles.userName}>SecureLabs AI</div>
				<div 
					className={styles.relativDiv}
					onMouseEnter={() => {
						setCreateResource(message?.id)
					}}
					onMouseLeave={() => {
						setCreateResource(false)
					}}
				>
					<pre className={styles.text}>
						<div dangerouslySetInnerHTML={{ __html: message.response }}></div>
					</pre>
					{createResource === message?.id && (
						<Btn
							className={styles.toResourceBtn}
							onClick={() => navigateToResource(message.response)}
						>
							+ Resource
						</Btn>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className={styles.chat}>
			<div
				ref={scrollableRef}
				className={styles.chatHistory}
				>
				{messages.length === 0 && (
					<div className={styles.chatLogo}>
						<img
							src={favicon}
							alt="Logo Chat"
						/>
						<div>How can I help you today?</div>
					</div>
				)}
				{messages.map((message, index) => (
					<div
						key={index}
						className={styles.chatMessage}
					>
						<div className={styles.messageUser}>
							<div className={styles.userIconDive}>
								<ProfileImage
									name={
										(users?.find(user => user.id === activeChat?.user_id)?.firstName || currentUser?.firstName) +
										" " +
										(users?.find(user => user.id === activeChat?.user_id)?.lastName || currentUser?.lastName)
									}
									className={styles.userIcon}
								/>
							</div>
							<div className={styles.textName}>
								<div className={styles.userName}>
									{(users?.find(user => user.id === activeChat?.user_id)?.firstName || currentUser?.firstName) +
										" " +
										(users?.find(user => user.id === activeChat?.user_id)?.lastName || currentUser?.lastName)}
								</div>
								<div className={styles.text}>{message.question}</div>
							</div>
						</div>
						<div className={styles.messageUser}>
							<div className={styles.userIconDive}>
								<ProfileImage
									name={"SecureLabs AI"}
									className={styles.userIcon}
								/>
							</div>
							{message?.response ? (
								renderMessage(message)
							) : (
								<div className={styles.dotsLoader}>
									<div className={cn(styles.dot, styles.dot1)}></div>
									<div className={cn(styles.dot, styles.dot2)}></div>
									<div className={cn(styles.dot, styles.dot3)}></div>
								</div>
							)}
						</div>
					</div>
				))}
			</div>
			<div className={styles.inputContainer}>
				{messages.length === 0 && (
					<div className={styles.promptSuggestions}>
						{promptSuggestions[selectedAssistant]?.map((suggestion, index) => (
							<div 
								key={index}
								className={cn(styles.promptSuggestion, {
									[styles.selectedSuggestion]: selectedSuggestion === index
								})}
								onClick={() => handleSuggestionClick(suggestion, index)}
							>
								{typeof suggestion === 'object' ? suggestion.text : suggestion}
							</div>
						))}
					</div>
				)}
				<div className={styles.formInput}>
					<textarea
						type="text"
						value={inputValue}
						onChange={e => setInputValue(e.target.value)}
						placeholder="Type a message..."
						className={cn(styles.chatInput, styles.inputStyle)}
						rows="1"
						onInput={e => autoResize(e.currentTarget)}
					/>
					<button
						type="submit"
						className={styles.chatButton}
						onClick={() => handleSubmit()}
					>
						{Loading ? (
							<Loader />
						) : (
							<SvgSprite
								spriteID={"send"}
								className={styles.inputSend}
							/>
						)}
					</button>
				</div>
			</div>
			{showPolicyForm && (
				<Popup
					isOpenedPopup={showPolicyForm}
					closePopup={() => setShowPolicyForm(false)}
				>
					<div className={styles.popupContent}>
						<div className={styles.policyForm}>
							<div className={styles.formHeader}>
								<h2>Generate Policies</h2>
								<p className={styles.formDescription}>
									Fill in the details below to generate customized policies aligned with your organization's needs and compliance requirements.
								</p>
							</div>
							
							<div className={styles.formContent}>
								<div className={styles.formColumn}>
									<div className={styles.formGroup}>
										<label>
											Company Name
											<span className={styles.required}>*</span>
											<SvgSprite
												spriteID="info"
												className={styles.infoIcon}
												onMouseEnter={() => setShowTooltip('companyName')}
												onMouseLeave={() => setShowTooltip(null)}
											/>
										</label>
										{showTooltip === 'companyName' && (
											<div className={styles.tooltip}>{fieldDescriptions.companyName}</div>
										)}
										<Input
											className={cn(styles.inputUser, { [styles.inputError]: formErrors.companyName })}
											value={policyFormData.companyName}
											onChange={e => {
												setPolicyFormData({...policyFormData, companyName: e.target.value})
												setFormErrors({...formErrors, companyName: ''})
											}}
											placeholder={fieldDescriptions.companyName}
											type="text"
										/>
										{formErrors.companyName && (
											<div className={styles.errorMessage}>{formErrors.companyName}</div>
										)}
									</div>

									<div className={styles.formGroup}>
										<label>
											Industry
											<span className={styles.required}>*</span>
											<SvgSprite
												spriteID="info"
												className={styles.infoIcon}
												onMouseEnter={() => setShowTooltip('industry')}
												onMouseLeave={() => setShowTooltip(null)}
											/>
										</label>
										{showTooltip === 'industry' && (
											<div className={styles.tooltip}>{fieldDescriptions.industry}</div>
										)}
										<Input
											className={cn(styles.inputUser, { [styles.inputError]: formErrors.industry })}
											value={policyFormData.industry}
											onChange={e => {
												setPolicyFormData({...policyFormData, industry: e.target.value})
												setFormErrors({...formErrors, industry: ''})
											}}
											placeholder={fieldDescriptions.industry}
											type="text"
										/>
										{formErrors.industry && (
											<div className={styles.errorMessage}>{formErrors.industry}</div>
										)}
									</div>
								</div>

								<div className={styles.formColumn}>
									<div className={styles.formGroup}>
										<label>
											Framework
											<span className={styles.required}>*</span>
											<SvgSprite
												spriteID="info"
												className={styles.infoIcon}
												onMouseEnter={() => setShowTooltip('framework')}
												onMouseLeave={() => setShowTooltip(null)}
											/>
										</label>
										{showTooltip === 'framework' && (
											<div className={styles.tooltip}>{fieldDescriptions.framework}</div>
										)}
										<Input
											className={cn(styles.inputUser, { [styles.inputError]: formErrors.framework })}
											value={policyFormData.framework}
											onChange={e => {
												setPolicyFormData({...policyFormData, framework: e.target.value})
												setFormErrors({...formErrors, framework: ''})
											}}
											placeholder={fieldDescriptions.framework}
											type="text"
										/>
										{formErrors.framework && (
											<div className={styles.errorMessage}>{formErrors.framework}</div>
										)}
									</div>

									<div className={styles.formGroup}>
										<label>
											About
											<span className={styles.required}>*</span>
											<SvgSprite
												spriteID="info"
												className={styles.infoIcon}
												onMouseEnter={() => setShowTooltip('about')}
												onMouseLeave={() => setShowTooltip(null)}
											/>
										</label>
										{showTooltip === 'about' && (
											<div className={styles.tooltip}>{fieldDescriptions.about}</div>
										)}
										<Input
											className={cn(styles.inputUser, { [styles.inputError]: formErrors.about })}
											value={policyFormData.about}
											onChange={e => {
												setPolicyFormData({...policyFormData, about: e.target.value})
												setFormErrors({...formErrors, about: ''})
											}}
											placeholder={fieldDescriptions.about}
											type="textarea"
										/>
										{formErrors.about && (
											<div className={styles.errorMessage}>{formErrors.about}</div>
										)}
									</div>
								</div>
							</div>

							<div className={styles.formFooter}>
								<p className={styles.footerNote}>
									* Required fields. The generated policies will be customized based on your input and selected framework.
								</p>
								<Btn
									className={styles.submitBtn}
									onClick={handlePolicyFormSubmit}
									disabled={Loading}
								>
									{Loading ? <Loader /> : "Generate Custom Policies"}
								</Btn>
							</div>
						</div>
					</div>
				</Popup>
			)}
		</div>
	)
}

export default Gpt3Form
